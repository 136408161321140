import React from "react"
import { Link } from 'gatsby-plugin-modal-routing'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import windowDimensions from "../components/windowDimensions"

class IndexPage extends React.Component {
    render() {
        return(
            <Layout>
                <SEO title="Home" />
                <div className="services">
                    <div>
                    {this.props.isMobilePortrait || this.props.isMobileLandscape ? 
                         <Link
                          to="/clients/"
                        >
                            <Image filename="bp.jpg"/>
                            <div className="overlay" style={{ backgroundColor: `orange`, }}></div>
                            <span id="clients">Clients</span>
                            <p>Hospitals, SNFs, Homehealth, Clinics: Find the perfect staff for <em>you</em></p>
                        </Link>
                    : 
                     <Link
                          to="/clients/"
                          asModal
                        >
                            <Image filename="bp.jpg"/>
                            <div className="overlay" style={{ backgroundColor: `orange`, }}></div>
                            <span id="clients">Clients</span>
                            <p>Hospitals, SNFs, Homehealth, Clinics: Find the perfect staff for <em>you</em></p>
                        </Link>
                    }
                       
                    </div>
                    <div>
                        {this.props.isMobilePortrait || this.props.isMobileLandscape ? 
                            <Link
                              to="/providers/"
                            >
                                <Image filename="comfort.jpg"/>
                                <div className="overlay" style={{ backgroundColor: `#9dce64`, }}></div>
                                <span id="providers">Providers</span>
                                <p>Nurses and allied healthcare professionals: Join our team today</p>
                            </Link>
                        :  
                            <Link
                              to="/providers/"
                              asModal
                            >
                                <Image filename="comfort.jpg"/>
                                <div className="overlay" style={{ backgroundColor: `#9dce64`, }}></div>
                                <span id="providers">Providers</span>
                                <p>Nurses and allied healthcare professionals: Join our team today</p>
                            </Link>
                        }
                    </div>
                    <div>
                        {this.props.isMobilePortrait || this.props.isMobileLandscape ? 
                            <Link
                              to="/caregivers/"
                            >
                                <Image filename="girl.jpg" />
                                <div className="overlay" style={{ backgroundColor: `#000099`, }}></div>
                                <span id="caregivers">Caregivers</span>
                                <p>Find family here</p>
                            </Link> : <Link
                              to="/caregivers/"
                              asModal
                            >
                                <Image filename="girl.jpg" />
                                <div className="overlay" style={{ backgroundColor: `#000099`, }}></div>
                                <span id="caregivers">Caregivers</span>
                                <p>Find family here</p>
                            </Link>
                        }
                    </div>
                </div>
                <div id="news" className="centered-content">
                    <h2>NEWS</h2>
                    <p>Hiring CNAs and LVNs who are interested in working in LA County.</p>
                </div>
                <div id="map" className="centered-content">
                    <iframe src="https://www.google.com/maps/d/embed?mid=1zNs0dS1PJwB9kclx1YQUIf0mgVV_-uND&z=9" width="640" height="480" title="Coverage Map"></iframe>
                </div>
              </Layout>
        );
    }
}


export default windowDimensions(IndexPage)
